import React, { useState } from "react"
import { useMutation } from "../../hooks/graphql"

import { Row, Col, Modal, Button, Icon, Input, message } from "antd"
import { ApolloError } from "apollo-client"

const REGISTER_MUTATION = `mutation ($input: Register!) {
  register(input: $input) {
    token
    username
    uid
  }
}`

interface RegisterProps {
  show: boolean
  onClose?: (e: React.MouseEvent<any, MouseEvent>) => void
}

export const Register: React.FunctionComponent<RegisterProps> = props => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const registerMutation = useMutation(REGISTER_MUTATION)
  const handleClick = async () => {
    const input = { username, email, password }
    if (!username) {
      message.warning("请输入用户名")
      return
    }
    if (!email) {
      message.warning("请输入邮箱")
      return
    }
    if (!password) {
      message.warning("请输入密码")
      return
    }
    setLoading(true)
    try {
      const { data } = await registerMutation({ variables: { input } })
      const { register } = data
      localStorage.setItem("token", register.token)
      window.location.reload()
    } catch (err) {
      if (err instanceof ApolloError && err.graphQLErrors.length !== 0) {
        message.error((err as ApolloError).graphQLErrors[0].message)
      } else {
        message.error("网路异常")
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      onCancel={props.onClose}
      visible={props.show}
      centered
      title="REGISTER"
      footer={null}
    >
      <Row type="flex" justify="center" align="middle">
        <Col span={12}>
          <Input
            prefix={<Icon type="user" />}
            value={username}
            placeholder="用户名"
            onChange={e => setUsername(e.target.value)}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col span={12}>
          <Input
            prefix={<Icon type="mail" />}
            value={email}
            placeholder="邮箱"
            onChange={e => setEmail(e.target.value)}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col span={12}>
          <Input
            prefix={<Icon type="lock" />}
            value={password}
            type="password"
            placeholder="密码"
            onChange={e => setPassword(e.target.value)}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col span={12}>
          <Button block type="primary" loading={loading} onClick={handleClick}>
            注册
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
