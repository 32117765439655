import React from "react"
import { withRouter, Link } from "react-router-dom"
import { getCurrentRoute } from "./routes"
import { Menu, Icon } from "antd"
const { Item } = Menu

export const ContestMenu = withRouter(props => {
  const { location, match } = props
  return (
    <Menu mode="horizontal">
      <Item key="contest">
        <Link to={match.url}>
          <Icon type="home" />
          首页
        </Link>
      </Item>
      <Item key="contestSubmit">
        <Link to={match.url + "/submit"}>
          <Icon type="solution" />
          提交状态
        </Link>
      </Item>
      <Item key="contestRank">
        <Link to={match.url + "/rank"}>
          <Icon type="trophy" />
          比赛榜单
        </Link>
      </Item>
    </Menu>
  )
})
