import React from "react"
import { Row, Col, Icon, Badge } from "antd"

const fontStyle: React.CSSProperties = {
  fontSize: 18,
  marginRight: 5
}

const BadgeStyle: React.CSSProperties = {
  backgroundColor: "#59c162"
}
interface IBadgeRowProps {
  icon: string
  count: number
  title: string
}
export const BadgeRow: React.FunctionComponent<IBadgeRowProps> = props => {
  const { icon, count, title } = props
  return (
    <Row type="flex" justify="space-between">
      <Col span={16}>
        <Icon style={fontStyle} type={icon} />
        {title}
      </Col>
      <Badge
        showZero={true}
        count={count}
        style={BadgeStyle}
        overflowCount={10000}
      />
    </Row>
  )
}
