const url =
  process.env.NODE_ENV === "production"
    ? "https://legend-se.hs97.cn/"
    : "http://localhost:5000/"

export function avatarUrl(filename: string): string {
  return url + "common/avatar/" + filename
}

export const avatarUploadUrl = url + "common/upload/avatar"

export const contestUrl = url + "common/contest"
