import React from "react"
import "./logo.less"

export function Logo() {
  return (
    <div className="logo">
      <span>LegendLand</span>
    </div>
  )
}
