import React from "react"
import marked from "marked"
import { Row, Typography } from "antd"
const { Title } = Typography

interface MarkedCardProps {
  title?: string
  markdown: string
}

export const MarkedCard: React.FunctionComponent<MarkedCardProps> = ({
  title = "",
  markdown
}) => {
  const inner = marked(markdown)
  return (
    <Row>
      {title && <Title level={4}>{title}</Title>}
      <Row dangerouslySetInnerHTML={{ __html: inner }} />
    </Row>
  )
}
