import React, { useState, useEffect, useRef } from "react"
import { SubmitTable } from "../../components/tables"
import { useQueryWithPagination } from "../../hooks/graphql"
import { Row, Pagination, message } from "antd"

import "./submit.less"
import { withRouter } from "react-router"
import { ISubmitResult } from "../../models/submit.model"

const PROBLEM_QUERY = `
query($offset: Int!, $limit: Int!)  {
  allSubmits(offset: $offset, limit: $limit) {
    id
    problemId
    state
    result
    memory
    time
    timestamp
    username
    language
  }
  allSubmitsCount
}`

interface QueryRes {
  allSubmits: ISubmitResult[]
  allSubmitsCount: number
}

export const Submits = withRouter(props => {
  const { history } = props
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [current, setCurrent] = useState(1)
  const contentRef = useRef<HTMLDivElement>(null)
  const { loading, error, data } = useQueryWithPagination<QueryRes>(
    limit,
    offset,
    PROBLEM_QUERY
  )

  const { allSubmits, allSubmitsCount } = data

  useEffect(() => {
    const height = contentRef.current!.clientHeight
    setLimit(Math.floor(height / 65))
  }, [data])
  const handleRowClick = (row: any) => {
    history.push(`/problem/${row.id}`)
  }

  return (
    <div className="submit" ref={contentRef}>
      <Row>
        <SubmitTable
          username
          submits={allSubmits || []}
          loading={loading}
          mode="ACM"
        />
      </Row>
      <Row type="flex" justify="center" className="submit-pagination">
        <Pagination
          current={current}
          pageSize={limit}
          total={allSubmitsCount || 0}
          onChange={page => {
            setOffset((page - 1) * limit)
            setCurrent(page)
          }}
        />
      </Row>
    </div>
  )
})
