import React, { useState, useEffect, useRef } from "react"
import { IProblemPagination, IProblem } from "../../models/problem.model"
import { ProblemTable } from "../../components/tables"
import { useQueryWithPagination } from "../../hooks/graphql"
import { Row, Pagination, message } from "antd"

import "./problem.less"
import { withRouter } from "react-router"

const PROBLEM_QUERY = `
query ($limit: Int, $offset: Int) {
  problems(limit: $limit, offset: $offset) {
    tot
    problems {
      id
      title
      totAc
      totSubmit
      timeLimit
      memoryLimit
    }
  }
}`

export const ProblemList = withRouter(props => {
  const { history } = props
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)
  const [problems, setProblems] = useState<IProblem[]>([])
  const contentRef = useRef<HTMLDivElement>(null)
  const { loading, error, data } = useQueryWithPagination<IProblemPagination>(
    limit,
    offset,
    PROBLEM_QUERY,
    "problems"
  )

  useEffect(() => {
    if (!loading && data) {
      setTotal(data.tot)
      setProblems(data.problems)
    }
    if (error) {
      setProblems([])
      message.error("网络异常！")
    }
  }, [data, error])

  useEffect(() => {
    const height = contentRef.current!.clientHeight
    setLimit(Math.floor(height / 65))
  }, [data])
  const handleRowClick = (row: any) => {
    history.push(`/problem/${row.id}`)
  }

  return (
    <div className="problem" ref={contentRef}>
      <Row>
        <ProblemTable
          problems={problems}
          loading={loading}
          rowClick={handleRowClick}
        />
      </Row>
      <Row type="flex" justify="center" className="problem-pagination">
        <Pagination
          current={current}
          pageSize={limit}
          total={total}
          onChange={page => {
            setOffset((page - 1) * limit)
            setCurrent(page)
          }}
        />
      </Row>
    </div>
  )
})
