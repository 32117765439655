import ApolloClient, { DefaultOptions } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { WebSocketLink } from "apollo-link-ws"
import { split } from "apollo-link"
import { setContext } from "apollo-link-context"
import { getMainDefinition } from "apollo-utilities"
import { InMemoryCache } from "apollo-cache-inmemory"
// export const apiUrl = process.env.NODE_ENV === 'production' ? 'http://'

const wsurl =
  process.env.NODE_ENV === "production"
    ? "wss://legend-se.hs97.cn/graphql"
    : "ws://localhost:5000/graphql"
const httpurl =
  process.env.NODE_ENV === "production"
    ? "https://legend-se.hs97.cn/graphql"
    : "http://localhost:5000/graphql"

const wsLink = new WebSocketLink({
  uri: wsurl,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("token")
      console.log(token)
      return {
        headers: {
          authorization: token ? token : ""
        }
      }
    }
  }
})
const httpLink = new HttpLink({
  uri: httpurl
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : ""
    }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === "OperationDefinition" && operation === "subscription"
  },
  wsLink,
  authLink.concat(httpLink)
)

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  }
}

export const graphQlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions
})

graphQlClient.link.request
