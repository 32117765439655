import React from "react"

interface ITextProps {
  color?: string
  type?: "success" | "error" | "processing" | "warning"
}

const colorMap: { [key: string]: string } = {
  success: "#52c41a",
  error: "#f5222d",
  processing: "#1890ff",
  warning: "#faad14"
}

const style: React.CSSProperties = {
  fontSize: 16,
  fontWeight: "bold"
}

export const Text: React.FunctionComponent<ITextProps> = props => {
  const { color, type } = props
  let textColor = "#d9d9d9"
  if (type) {
    textColor = colorMap[type]
  }
  if (color) {
    textColor = color
  }
  return <span style={{ ...style, color: textColor }}>{props.children}</span>
}
