import React from "react"
import { Card, Col, Row } from "antd"
import { RecentContest } from "../../components/tables"
import "./index.less"
export function Index() {
  return (
    <div className="index">
      <Row justify="space-around" type="flex">
        <Col span={24}>
          <Card title="近期比赛">
            <RecentContest />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
