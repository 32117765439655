import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import {
  DailySubmit,
  UserContest,
  UserAvatar,
  SubmitState
} from "../../components/user"
import { Row, Col, Card, Spin, Empty, Upload, Avatar, message } from "antd"
import { useQuery } from "../../hooks/graphql"
import "./profile.less"
import {
  IDailySubmit,
  IUser,
  IUserProfile,
  IUserSubmitCount
} from "../../models/user.model"
import { useUserInfo } from "../../hooks/user"
import { Text } from "../../components/common"
import { SubmitTable } from "../../components/tables"
import { ISubmitResult } from "../../models/submit.model"

const USER_QUERY = `query ($username: String!) {
  getDailySubmit(username: $username) {
    date
    day
    submit
    week
  }
  user(username: $username) {
    rating
    accept
    totSubmit
    contestCount
    resolve
    avatar
    username
  }
  userSubmitClassify(username: $username) {
    state
    count
  }
  userSubmits(username: $username, limit: 10) {
    id
    state
    result
    memory
    time
    timestamp
    problemId
  }
}`

interface IUserQuery {
  getDailySubmit: IDailySubmit[]
  user: IUserProfile
  userSubmitClassify: IUserSubmitCount[]
  userSubmits: ISubmitResult[]
}

export const Profile = withRouter(props => {
  const { match } = props
  const username = match.params.id
  const userInfo = useUserInfo()
  const [avatar, setAvatar] = useState("default")
  const { loading, error, data } = useQuery<IUserQuery>(USER_QUERY, {
    variables: { username }
  })
  const { getDailySubmit, user, userSubmitClassify, userSubmits } = data

  const totSubmitLastYear =
    (getDailySubmit && getDailySubmit.reduce((pre, s) => pre + s.submit, 0)) ||
    0

  useEffect(() => {
    if (user && user.avatar) {
      setAvatar(user.avatar)
    }
  }, [user])

  return (
    <>
      {loading ? (
        <Spin />
      ) : error ? (
        <Empty />
      ) : (
        <div className="profile">
          <Row type="flex" justify="space-around" align="top">
            <Col span={6}>
              <Card title="基本信息">
                <Row type="flex" justify="space-around">
                  <Col span={16}>
                    <UserAvatar
                      upload={userInfo.username === username}
                      onFinished={(res: any) => setAvatar(res)}
                      avatar={avatar}
                    />
                  </Col>
                  <Col span={8}>
                    <Row type="flex">
                      <Text color="#000006">{username}</Text>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <UserContest
                style={{ marginTop: 10 }}
                count={user.contestCount}
                rating={user.rating}
              />
              <SubmitState
                totSubmit={user.totSubmit}
                accept={user.accept}
                resolve={user.resolve}
                data={userSubmitClassify}
              />
            </Col>
            <Col span={17}>
              <Card
                title={`在过去的一年里你有${totSubmitLastYear}个提交`}
                style={{ width: "100%", marginBottom: 10 }}
              >
                <DailySubmit data={getDailySubmit} username={username} />
              </Card>
              <Card title="最近10次提交">
                <SubmitTable
                  submits={userSubmits}
                  loading={loading}
                  mode="ACM"
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
})
