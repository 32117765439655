import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Row, Col, Input, Form, InputNumber } from "antd"
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}
export const ProblemEdit = withRouter(props => {
  const { match } = props
  const problemId = match.params.id
  const [timeLimit, setTimeLimit] = useState(0)
  const [memoryLimit, setMemoryLimit] = useState(0)
  const [display, setDisplay] = useState(false)
  const [title, setTitle] = useState("")
  const [input, setInput] = useState("")
  const [output, setOutput] = useState("")
  const [describe, setDescribe] = useState("")
  const [extraInfo, setExtraInfo] = useState("")

  return (
    <div className="problem-edit">
      <Form {...formItemLayout}>
        <Form.Item label="标题">
          <Input onChange={e => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="时间限制">
          <InputNumber
            formatter={n => n + " ms"}
            onChange={n => setTimeLimit(n!)}
          />
        </Form.Item>
      </Form>
    </div>
  )
})
