export interface IProblem {
  id?: number
  title?: string
  describe?: string
  memoryLimit?: number
  timeLimit?: number
  extraInfo?: string
  totSubmit?: number
  totAc?: number
  input?: string
  output?: string
  tags?: string[]
}

export interface IProblemPagination {
  problems: IProblem[]
  tot: number
  offset: number
  limit: number
}

export interface ISubmit {
  code: string
  problemId: number
  language: string
}

export enum JudgeResult {
  "Pending" = 0,
  "Accepted" = 1,
  "Wrong Answer" = 2,
  // TLE is Time Limit Exceeded
  "Time Limit Exceeded" = 3,
  // MLE is Memory Limit Exceeded
  "Memory Limit Exceeded" = 4,
  // OLE is Output Limit Exceeded
  "Output Limit Exceeded" = 5,
  // RE is Runtime Error
  "Runtime Error" = 6,
  // SE is System Error
  "System Error" = 7,
  // CE is Compiler Error
  "Compiler Error" = 8,
  // CTLE is Compiler Time limit exceeded
  "Compiler Time limit exceeded" = 9
}

export enum SubmitState {
  "Running" = "Running",
  "Pending" = "Pending",
  "Finished" = "Finished"
}

export interface ISubmitRes {
  result: JudgeResult
  score: number
  state: SubmitState
}
