import React from "react"
import { Popover, Icon, notification } from "antd"
import { useSubscription } from "../../hooks/graphql"

const NOTICE_SUBSCRIPTION = `subscription {
  noticeGlobal {
    message
    title
  }
}`

export function Notices() {
  const notice = useSubscription(NOTICE_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData
      const { message, title } = data["noticeGlobal"]
      notification.info({ message: title, description: message })
    }
  })
  return (
    <Popover>
      <Icon type="mail" />
    </Popover>
  )
}
