import React from "react"
import { Table } from "antd"
import { ColumnProps } from "antd/lib/table"
import { ISubmitResult, Language } from "../../models/submit.model"
import { Text } from "../../components/common"
import { JudgeResult } from "../../models/problem.model"
import { format } from "../../lib/date"
import { Link } from "react-router-dom"

const scoreColumn: ColumnProps<ISubmitResult> = {
  title: "得分",
  dataIndex: "score",
  width: 220,
  render: data => <Text type={data === 100 ? "error" : "warning"}>{data}</Text>
}

const columns: ColumnProps<ISubmitResult>[] = [
  {
    key: "id",
    title: "题号",
    render: (data, record) => 1000 + record.problemId!,
    width: 100
  },
  {
    key: "username",
    dataIndex: "username",
    title: "用户名",
    render: data => <Link to={"/profile/" + data}>{data}</Link>,
    width: 300
  },
  {
    key: "submit-time",
    title: "提交时间",
    render: (data, record) => format(record.timestamp)
  },
  {
    key: "language",
    title: "语言",
    dataIndex: "language",
    render: l => Language[l]
  },
  {
    key: "result",
    title: "结果",
    render: (data, record) => {
      const typeMap = [
        "processing",
        "error",
        "success",
        "warning",
        "warning",
        "warning",
        "success",
        "error",
        "error",
        "error"
      ]
      return (
        <Text type={typeMap[record.result!] as any}>
          {JudgeResult[record.result!]}
        </Text>
      )
    },
    width: 220
  },
  {
    key: "time",
    dataIndex: "time",
    title: "时间/MS",
    width: 180
  },
  {
    key: "memory",
    dataIndex: "memory",
    title: "空间/KB",
    width: 180
  }
]

interface ISubmitTableProps {
  submits: ISubmitResult[]
  loading: boolean
  mode: "ACM" | "NOI" | "OI"
  username?: boolean
}

export const SubmitTable: React.FunctionComponent<
  ISubmitTableProps
> = props => {
  const { submits, loading, mode } = props
  const realColumns = columns.slice()
  if (mode !== "ACM") {
    realColumns[4] = scoreColumn
  }

  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={submits}
      columns={realColumns}
      pagination={false}
    />
  )
}
