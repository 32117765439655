import React, { useState, useEffect, useRef } from "react"
import { withRouter } from "react-router-dom"
import { useQuery, useQueryWithPagination } from "../../hooks/graphql"
import { ISubmitResult } from "../../models/submit.model"
import { message, Row, Pagination } from "antd"
import { SubmitTable } from "../../components/tables"

import "./submit.less"

const SUBMIT_COUNT_QUERY = `query ($id: Int) {
  contestSubmitCount(id: $id)
  contest(id: $id) {
    mode
    problemCount
  }
}`

const SUBMIT_QUERY = `query ($id: Int, $limit: Int, $offset: Int) {
  contestSubmit(id: $id, limit: $limit, offset: $offset) {
    id
    username
    score
    result
    time
    memory
    state
    problemId
    timestamp
    language
  }
}`

export const ContestSubmit = withRouter(props => {
  const { match } = props
  const contestId = parseInt(match.params.id, 10)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [current, setCurrent] = useState(1)
  const [submits, setSubmits] = useState<ISubmitResult[]>([])
  const contentRef = useRef<HTMLDivElement>(null)
  const {
    data: { contestSubmitCount: count, contest },
    loading,
    error
  } = useQuery<any>(SUBMIT_COUNT_QUERY, { variables: { id: contestId } })
  const submit = useQueryWithPagination<ISubmitResult[]>(
    limit,
    offset,
    SUBMIT_QUERY,
    "contestSubmit",
    { id: contestId }
  )
  useEffect(() => {
    if (!loading && !error) {
      setTotal(count)
    }
  }, [count])
  useEffect(() => {
    if (!submit.loading && !submit.error) {
      setSubmits(submit.data)
    }
    if (submit.error) {
      message.error("网络异常！")
    }
  }, [submit])

  useEffect(() => {
    const height = contentRef.current!.clientHeight
    setLimit(Math.floor(height / 65))
  }, [submit])

  return (
    <div className="submit" ref={contentRef}>
      <Row>
        <SubmitTable
          mode={(contest && contest.mode) || "ACM"}
          submits={submits}
          loading={submit.loading || loading}
        />
      </Row>
      <Row type="flex" justify="center" className="submit-pagination">
        <Pagination
          current={current}
          pageSize={limit}
          total={total}
          onChange={page => {
            setOffset((page - 1) * limit)
            setCurrent(page)
          }}
        />
      </Row>
    </div>
  )
})
