import React, { useEffect, useState } from "react"
import { withRouter } from "react-router"
import "./detail.less"
import {
  PageHeader,
  Row,
  Col,
  Spin,
  Typography,
  Empty,
  Button,
  Popover,
  message
} from "antd"
import { Text } from "../../components/common"
import { MarkedCard } from "../../components/common"
import * as qs from "query-string"
import { Controlled as CodeMirror } from "react-codemirror2"
require("codemirror/mode/clike/clike")
import { useQuery, useSubscription } from "../../hooks/graphql"
import {
  IProblem,
  ISubmit,
  ISubmitRes,
  SubmitState,
  JudgeResult
} from "../../models/problem.model"
import { Select } from "../../components/common"

const { Title, Paragraph } = Typography

const PROBLEM_QUERY = `query ($problemId: Int) {
  problem(id: $problemId) {
    title
    describe
    timeLimit
    memoryLimit
    tags
    totAc
    totSubmit
    input
    output
    extraInfo
  }
}`

const PROBLEM_SAMPLE = `query ($problemId: Int) {
  sampleData(id: $problemId) {
    in
    out
  }
}`

const CONTEST_PROBLEM_QUERY = `query($contestId: Int, $problemId: Int) {
  contestProblem(id: $contestId, problemId: $problemId) {
    title
    describe
    timeLimit
    memoryLimit
    tags
    totAc
    totSubmit
    input
    output
    extraInfo
  }
}`

const SUBMIT_PROBLEM = `subscription($input: Submit) {
  submit(input: $input) {
    state
    score
    result
  }
}`

const CONTEST_SUBMIT = `subscription ($input: Submit, $contestId: Int) {
  contestSubmitCode(id: $contestId, input: $input) {
    state
    score
    result
  }
}`

const TEST_SUBMIT = `subscription ($input: Submit) {
  submitTest(input: $input) {
    state
    score
    result
  }
}`

const SelectEnum = {
  "C++": "CPP",
  Java: "JAVA"
}

const CodeEditorType: { [key: string]: string } = {
  CPP: "text/x-c++src",
  JAVA: "text/x-java"
}

interface DescriptionProps {
  term: string
  children: React.ReactNode
  span?: number
}

const Description: React.FunctionComponent<DescriptionProps> = ({
  term,
  children,
  span = 8
}) => (
  <Col span={span}>
    <Row type="flex" justify="start">
      <Col className="description-term">{term}</Col>
      <Col className="description-children">{children}</Col>
    </Row>
  </Col>
)

export const ProblemDetail = withRouter(props => {
  const { match } = props
  let contestId = -1
  let problemId = -1
  const query = qs.parse(window.location.search)
  if (query.from === "contest") {
    contestId = parseInt(match.params.id, 10)
    problemId = parseInt(match.params.problem, 10)
  } else {
    problemId = parseInt(match.params.id, 10)
  }
  const [score, setScore] = useState(0)
  const [result, setResult] = useState(0)
  const [code, setCode] = useState("")
  const [language, setLanguage] = useState("CPP")
  const [skip, setSkip] = useState(true)
  // const [testSkip, setTestSkip] = useState(true)
  const [submitState, setSubmitState] = useState<SubmitState>("" as any)
  const [input, setInput] = useState<ISubmit>({
    code: "",
    problemId,
    language
  })
  const SAMPLEQUERY = contestId === -1 ? PROBLEM_SAMPLE : PROBLEM_SAMPLE
  const samplePickKey = contestId === -1 ? "sampleData" : "sampleData"
  const sampleQuery = useQuery<any[]>(
    SAMPLEQUERY,
    { variables: { problemId } },
    samplePickKey
  )

  const QUERY = contestId === -1 ? PROBLEM_QUERY : CONTEST_PROBLEM_QUERY
  const pickKey = contestId === -1 ? "problem" : "contestProblem"
  const { data, loading, error } = useQuery<IProblem>(
    QUERY,
    {
      variables: { problemId, contestId }
    },
    pickKey
  )
  const SUBSCRIPTION = contestId === -1 ? SUBMIT_PROBLEM : CONTEST_SUBMIT
  const submitPickKey = contestId === -1 ? "submit" : "contestSubmitCode"
  useSubscription(SUBSCRIPTION, {
    variables: {
      input,
      contestId
    },
    skip,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data, error } = subscriptionData
      if (error) {
        setSkip(true)
      }
      const { score, state, result } = data[submitPickKey] as ISubmitRes
      setSubmitState(state)
      setScore(score)
      setResult(result)
      if (state === SubmitState.Finished) {
        setSkip(true)
      }
    }
  })
  // const TESTSUBSCRIPTION = contestId === -1 ? TEST_SUBMIT : TEST_SUBMIT
  // useSubscription(TESTSUBSCRIPTION, {
  //   variables: {
  //     input,
  //     contestId
  //   },
  //   skip: testSkip,
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     const { data, error } = subscriptionData
  //     if (error) {
  //       setTestSkip(true)
  //     }
  //   }
  // })
  useEffect(() => {
    ;(window as any).MathJax.Hub.Queue([
      "Typeset",
      (window as any).MathJax.Hub,
      "show"
    ])
  }, [data])

  const handleSubmit = (mode: "test" | "normal" = "normal") => {
    const state: ISubmit = {
      ...input,
      code,
      language
    }
    if (code.length === 0) {
      message.error("？？？？")
      return
    }
    if (mode === "test") {
      // setTestSkip(false)
    } else {
      setSkip(false)
    }
    setSubmitState(SubmitState.Pending)
    setInput(state)
  }

  return (
    <div className="problem-detail">
      {loading ? (
        <Spin />
      ) : error || !data ? (
        <Empty />
      ) : (
        <>
          <PageHeader title={`Problem: ${problemId + 1000}`} />
          <Row type="flex" className="problem-detail-content">
            <Col span={12} className="problem-detail__left">
              <Title level={3}>{data.title}</Title>
              <Row>
                <Description term="时间限制：">{data.timeLimit} MS</Description>
                <Description term="空间限制：">
                  {data.memoryLimit} KB
                </Description>
              </Row>
              <Row className="margin-top">
                <Description term="提交次数：">{data.totSubmit}</Description>
                <Description term="通过次数：">{data.totAc}</Description>
              </Row>
              <Row type="flex" className="problem-detail__describe" style={{flex: '1 auto'}}>
                <Col span={24} className="flex-col">
                  <MarkedCard markdown={data.describe!} />
                  <MarkedCard title="Input:" markdown={data.input!} />
                  <MarkedCard title="Output:" markdown={data.output!} />
                  {data.extraInfo && <MarkedCard markdown={data.extraInfo!} />}
                </Col>
              </Row>
            </Col>
            <Col span={12} className="problem-detail__right">
              <Row type="flex">
                <Col span={6} className="problem-detail__right__title">
                  CODE EDITOR
                </Col>
                <Col span={18} className="problem-detail__toolbar">
                  <Select
                    style={{ width: 130 }}
                    defaultValue={language}
                    enumType={SelectEnum}
                    onChange={val => setLanguage(val.toString())}
                  />
                </Col>
              </Row>
              <Row type="flex" style={{ flex: 3 }}>
                <Col span={24}>
                  <CodeMirror
                    value={code}
                    onBeforeChange={(editor, data, value) => setCode(value)}
                    options={{
                      mode: CodeEditorType[language],
                      theme: "material",
                      lineNumbers: true
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" className="sample-bg">
                <Col span={6} className="problem-detail__right__title">
                  SAMPLE TESTS
                </Col>
              </Row>
              <Row
                className="sample-list sample-bg flex-col"
                type="flex"
                style={{ flex: 1 }}
              >
                {(sampleQuery.data || []).map((i, index) => {
                  return (
                    <Row key={index} type="flex">
                      <Popover content={i.in} title="输入" trigger="hover">
                        <Paragraph
                          strong
                          style={{ color: "#ffffff" }}
                          copyable={{ text: i.in }}
                        >
                          样例{index + 1}输入
                        </Paragraph>
                      </Popover>
                      <Popover content={i.out} title="输入" trigger="hover">
                        <Paragraph
                          strong
                          style={{ color: "#ffffff" }}
                          type="warning"
                          copyable={{ text: i.out }}
                        >
                          样例{index + 1}输出
                        </Paragraph>
                      </Popover>
                    </Row>
                  )
                })}
              </Row>
              <Row
                type="flex"
                justify="space-between"
                className="problem-detail__right__footer"
              >
                <Col push={1}>
                  {submitState === SubmitState.Finished ? (
                    result === 0 ? (
                      query.mode === 'NOI' ? 
                      <Text color="#6cc789">提交成功</Text> : 
                      <Text color="#6cc789">Score: {score}</Text>
                    ) : (
                      <Text color="#6cc789">{JudgeResult[result]}</Text>
                    )
                  ) : (
                    <Text type="warning">{submitState}</Text>
                  )}
                </Col>
                <Col pull={1}>
                  {/* <Button
                    onClick={() => handleSubmit("test")}
                    type="primary"
                    shape="round"
                  >
                    运行样例
                  </Button> */}
                  <Button
                    onClick={() => handleSubmit()}
                    shape="round"
                    style={{ marginLeft: "10px" }}
                  >
                    提交代码
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
})
