import React, { useRef, useEffect, useState } from "react"
import { IDailySubmit } from "../../models/user.model"
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from "bizcharts"

interface DailySubmitProps {
  username?: string
  data: IDailySubmit[]
}

const cols = {
  day: {
    type: "cat",
    values: ["", "Mon", "", "Wed", "", "Fri", ""]
  },
  week: {
    type: "cat"
  },
  commits: {
    sync: true
  }
}

export const DailySubmit: React.FunctionComponent<DailySubmitProps> = props => {
  const { username, data } = props
  const [chartInstance, setChartInstance] = useState(null)
  const shapeObj = Shape.registerShape!("polygon", "boundary-polygon", {
    draw(cfg: any, container: any) {
      if (!Util.isEmpty(cfg.points)) {
        const attrs: any = {
          stroke: "#fff",
          lineWidth: 1,
          fill: cfg.color,
          fillOpacity: cfg.opacity
        }
        const points = cfg.points
        const path = [
          ["M", points[0].x, points[0].y],
          ["L", points[1].x, points[1].y],
          ["L", points[2].x, points[2].y],
          ["L", points[3].x, points[3].y],
          ["Z"]
        ]
        attrs.path = (this as any).parsePath(path)
        const polygon = container.addShape("path", {
          attrs
        })
        container.sort()
        return polygon
      }
    }
  })
  useEffect(() => {
    if (chartInstance) {
      ;(chartInstance as any).forceFit()
    }
  }, [chartInstance, data])
  return (
    <div>
      <Chart
        height={130}
        data={data}
        scale={cols}
        padding={[0, 0, 0, 40]}
        forceFit
        onGetG2Instance={chart => setChartInstance(chart as any)}
      >
        <Tooltip title="date" />
        <Axis
          name="week"
          position="top"
          tickLine={false}
          line={false}
          label={{
            offset: 12,
            textStyle: {
              fontSize: 12,
              fill: "#666",
              textBaseline: "top"
            },
            formatter: val => {
              return ""
            }
          }}
        />
        <Axis name="day" grid={false} />
        <Geom
          type="polygon"
          position="week*day*date"
          shape="boundary-polygon"
          color={["submit", "#f5f5f5-#1890FF-#0050B3"]}
        />
        <Coord reflect="y" />
      </Chart>
    </div>
  )
}
