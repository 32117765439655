import React from "react"
import { withRouter } from "react-router"
import { useQuery, useMutation } from "../../hooks/graphql"
import { Spin, Empty, message } from "antd"
import { IContest, ContestState } from "../../models/contest.model"
import { ContestListItem } from "../../components/contest/ContestListItem"

import "./contest.less"

const CONTEST_QUERY = `query {
  contests {
    id
    startAt
    duration
    startRegisterAt
    registerDuration
    title
    mode
    permissionType
    joined
  }
}`

const REGISTER_MUTATION = `mutation ($id: Int!) {
  registerContest(id: $id)
}`

export const ContestList = withRouter(props => {
  const { history } = props
  const { loading, data, error } = useQuery<IContest[]>(
    CONTEST_QUERY,
    {},
    "contests"
  )

  const handleRowClick = (id: number) => {
    history.push(`/contest/${id}`)
  }

  const registerContest = useMutation(REGISTER_MUTATION)

  const handleJoinClick = async (id: number) => {
    try {
      await registerContest({ variables: { id } })
      message.success("注册成功")
    } catch (err) {
      message.error("注册失败")
    }
  }

  return (
    <>
      {loading ? (
        <Spin />
      ) : error || !data || data.length === 0 ? (
        <Empty />
      ) : (
        <div className="contest">
          <div>
            {data.map(c => (
              <ContestListItem
                onClick={() => handleRowClick(c.id!)}
                onJoinClick={() => handleJoinClick(c.id!)}
                key={c.id!}
                start={c.startAt!}
                startRegister={c.startRegisterAt!}
                state={ContestState.Pending}
                title={c.title!}
                duration={c.duration!}
                contestId={c.id!}
                permissionMode={c.permissionType!}
                registerDuration={c.registerDuration!}
                joined={c.joined!}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
})
