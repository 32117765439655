import moment from "moment"

export function isNowBeforeDate(
  src: moment.MomentInput,
  addMs: number = 0
): boolean {
  const now = moment()
  const date = moment(src).add(addMs, "ms")
  return now.isBefore(date)
}

export function isNowAfterDate(
  src: moment.MomentInput,
  addMs: number = 0
): boolean {
  const now = moment()
  const date = moment(src).add(addMs, "ms")
  return now.isAfter(date)
}

export function format(
  date: moment.MomentInput,
  s: string = "YYYY.MM.DD HH:mm:ss"
): string {
  return moment(date).format(s)
}

export function formatDuration(duration: number): string {
  const ms = duration % 1000
  const s = Math.floor(duration / 1000) % 60
  const m = Math.floor(duration / 1000 / 60) % 60
  const h = Math.floor(duration / 1000 / 60 / 60)
  const ss = s < 10 ? "0" + s : s
  const mm = m < 10 ? "0" + m : m
  const hh = h < 10 ? "0" + h : h
  return hh + ":" + mm + ":" + ss
}
