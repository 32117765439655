import React, { useState, useEffect } from "react"

export function useFetch<T>(url: string): { data: T; loading: boolean } {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  useEffect(() => {
    setLoading(true)
    fetch(url, { mode: "cors" })
      .then(async res => {
        const json = await res.json()
        setData(json)
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }, [url])
  return { data, loading }
}
