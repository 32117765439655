import React, { useEffect } from "react"
import { Table } from "antd"
import { useFetch } from "../../hooks/fetch"
import { recentContestItem } from "../../models/contest.model"
import { ColumnProps } from "antd/lib/table"
import { contestUrl } from "../../lib/request"

const columns: ColumnProps<recentContestItem>[] = [
  {
    title: "OJ",
    dataIndex: "oj"
  },
  {
    title: "Name",
    render: (data, record) => <a href={record.link}>{record.name}</a>
  },
  {
    title: "Start Time",
    dataIndex: "start_time"
  },
  {
    title: "Week",
    dataIndex: "week"
  }
]

export function RecentContest() {
  const { data, loading } = useFetch<recentContestItem[]>(contestUrl)
  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={data}
      loading={loading}
    />
  )
}
