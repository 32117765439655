import React, { Component } from "react"
import { Layout, Row, Col } from "antd"
import { BrowserRouter as Router, Route as RRoute } from "react-router-dom"
import { Menu, Route, ContestMenu } from "./components/router"
import { Logo } from "./components/logo/Logo"
import { ApolloProvider } from "react-apollo-hooks"
import { User } from "./components/user"

import "./App.less"
import { graphQlClient } from "./lib/graphql"

const { Header, Sider, Footer, Content } = Layout

function App() {
  return (
    <Router>
      <ApolloProvider client={graphQlClient}>
        <Layout>
          <Sider theme="light">
            <Logo />
            <Menu />
          </Sider>
          <Layout>
            <Header className="header">
              <Row justify="space-between" type="flex" style={{ flex: 1 }}>
                <Col className="flex-col-end">
                  <RRoute
                    path="/contest/:id"
                    component={ContestMenu}
                    extra={false}
                  />
                </Col>
                <User />
              </Row>
            </Header>
            <Content className="content">
              <Route />
            </Content>
          </Layout>
        </Layout>
      </ApolloProvider>
    </Router>
  )
}
export default App
