import React, { useState } from "react"
import { UserInfo, Notices } from "./index"
import { useUserInfo } from "../../hooks/user"
import { Button, Row, Col } from "antd"
import { Login } from "./Login"
import { Register } from "./Register"
export function User() {
  const user = useUserInfo()
  const [register, setRegister] = useState(false)
  const [login, setLogin] = useState(false)

  return (
    <>
      {user.username ? (
        <Col span={2}>
          <Row type="flex" align="middle" justify="space-around">
            <Notices />
            <UserInfo />
          </Row>
        </Col>
      ) : (
        <Col span={3} className="flex-col">
          <Row type="flex" align="middle" justify="space-around">
            <Button type="primary" onClick={() => setRegister(true)}>
              注册
            </Button>
            <Button onClick={() => setLogin(true)}>登录</Button>
          </Row>
          <Login onClose={() => setLogin(false)} show={login} />
          <Register onClose={() => setRegister(false)} show={register} />
        </Col>
      )}
    </>
  )
}
