import React, { useState, FunctionComponent, useEffect } from "react"
import { Modal, Input, Button, Row, Col, Icon, message } from "antd"
import { useQuery, useMutation } from "../../hooks/graphql"
import { ApolloError } from "apollo-client"

interface LoginProps {
  show: boolean
  onClose?: (e: React.MouseEvent<any, MouseEvent>) => void
}

const LOGIN_MUTATION = `mutation ($input: Login!) {
  login(input: $input) {
    username
    token
  }
}`

export const Login: FunctionComponent<LoginProps> = props => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const loginMutation = useMutation(LOGIN_MUTATION)
  const handleClick = async () => {
    const input = { username, password }
    if (!username) {
      message.warning("请输入用户名")
      return
    }
    if (!password) {
      message.warning("请输入密码")
      return
    }
    setLoading(true)
    try {
      const { data } = await loginMutation({ variables: { input } })
      const { login } = data
      localStorage.setItem("token", login.token)
      window.location.reload()
    } catch (err) {
      if (err instanceof ApolloError && err.graphQLErrors.length !== 0) {
        message.error((err as ApolloError).graphQLErrors[0].message)
      } else {
        message.error("网路异常")
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      onCancel={props.onClose}
      visible={props.show}
      centered
      title="LOGIN"
      footer={null}
    >
      <Row type="flex" justify="center" align="middle">
        <Col span={12}>
          <Input
            prefix={<Icon type="user" />}
            value={username}
            placeholder="用户名"
            onChange={e => setUsername(e.target.value)}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col span={12}>
          <Input
            prefix={<Icon type="lock" />}
            value={password}
            type="password"
            placeholder="密码"
            onChange={e => setPassword(e.target.value)}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ marginTop: 20 }}
      >
        <Col span={12}>
          <Button block type="primary" loading={loading} onClick={handleClick}>
            登录
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
