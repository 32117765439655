import React from "react"
import { Route as RRouter, Switch } from "react-router-dom"
import { routes } from "./routes"

export function Route() {
  return (
    <>
      <Switch>
        {routes.map((route, index) => (
          <RRouter
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        {/* {contestRoutes.map((route, index) => (
          <RRouter
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))} */}
      </Switch>
    </>
  )
}
