import React from "react"
import { ContestState as ContestStateC, workContestState } from "./ContestState"
import { ContestState } from "../../models/contest.model"
import { Row, Col, Typography, Statistic, Tag, Button } from "antd"

import "./contestListItem.less"
import {
  format,
  formatDuration,
  isNowAfterDate,
  isNowBeforeDate
} from "../../lib/date"

const { Title } = Typography
const { Countdown } = Statistic

interface ContestListItemProps {
  start: number
  duration: number
  startRegister: number
  registerDuration: number
  title: string
  state: ContestState
  permissionMode: string
  contestId: number
  joined: boolean
  onClick?: () => void
  onJoinClick?: () => void
}

interface StartTimeProps {
  start: number
}
const StartTime: React.FunctionComponent<StartTimeProps> = props => {
  const now = +new Date()
  const start = props.start
  const limit = 1000 * 60 * 60 * 24
  return (
    <>
      {start - now > 0 && start - now <= limit ? (
        <Row type="flex" align="middle">
          <span>即将开始：</span>
          <Countdown valueStyle={{ fontSize: 18 }} value={start} />
        </Row>
      ) : (
        <Row>
          <span>开始时间：</span>
          <span className="contest-item__font--normal">{format(start)}</span>
        </Row>
      )}
    </>
  )
}

interface DurationTimeProps {
  start: number
  duration: number
}

const DurationTime: React.FunctionComponent<DurationTimeProps> = props => {
  const { start, duration } = props
  const contestState = workContestState(start, duration)
  return (
    <>
      {contestState === ContestState.Running ? (
        <Row type="flex" align="middle">
          <span>剩余：</span>
          <Countdown valueStyle={{ fontSize: 18 }} value={start + duration} />
        </Row>
      ) : (
        <Row>
          <span>时长：</span>
          <span className="contest-item__font--normal">
            {formatDuration(duration)}
          </span>
        </Row>
      )}
    </>
  )
}

export function workRegister(
  startRegister: number,
  registerDuration: number
): boolean {
  if (
    isNowAfterDate(startRegister, registerDuration) ||
    isNowBeforeDate(startRegister)
  ) {
    return false
  }
  return true
}

export const ContestListItem: React.FunctionComponent<
  ContestListItemProps
> = props => {
  const {
    title,
    permissionMode,
    start,
    duration,
    joined,
    registerDuration,
    startRegister
  } = props
  const onClick = props.onClick ? props.onClick : () => ({})
  const onJoinClick = props.onJoinClick ? props.onJoinClick : () => ({})

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className="contest-item"
      onClick={onClick}
    >
      <Col span={12}>
        <Row type="flex" justify="start" align="middle">
          <ContestStateC start={start} duration={duration} />
          <div className="contest-item__title">{title}</div>
          <Tag color="orange">{permissionMode}</Tag>
        </Row>
      </Col>
      <Col span={6}>
        <StartTime start={start} />
      </Col>
      <Col span={4}>
        <DurationTime start={start} duration={duration} />
      </Col>
      <Col span={2}>
        {!joined && workRegister(startRegister, registerDuration) && (
          <Button
            onClick={e => {
              e.stopPropagation()
              onJoinClick()
            }}
            type="primary"
          >
            注册
          </Button>
        )}
      </Col>
    </Row>
  )
}
