import React from "react"
import { Table, Progress } from "antd"
import { IProblem } from "../../models/problem.model"
import { ColumnProps } from "antd/lib/table"

interface IProblemTableProps {
  problems: IProblem[]
  loading: boolean
  rowClick: (record: IProblem, index: number) => void
}

const columns: ColumnProps<IProblem>[] = [
  {
    key: "id",
    title: "题号",
    render: (data, record) => 1000 + record.id!,
    width: 100
  },
  {
    key: "title",
    dataIndex: "title",
    title: "题目"
  },
  {
    key: "timeLimit",
    dataIndex: "timeLimit",
    title: "时间限制/MS",
    width: 180
  },
  {
    key: "memoryLimit",
    dataIndex: "memoryLimit",
    title: "空间限制/KB",
    width: 180
  },
  {
    key: "totSubmit",
    dataIndex: "totSubmit",
    title: "提交次数",
    width: 180
  }
]

export const ProblemTable: React.FunctionComponent<
  IProblemTableProps
> = props => {
  const { problems, loading, rowClick } = props
  let realColumns = columns
  if (problems.length !== 0) {
    realColumns = columns.filter(c => problems[0].hasOwnProperty(c.key!))
    if (problems[0].totSubmit && problems[0].totAc) {
      realColumns = [
        ...realColumns,
        {
          key: "ac",
          title: "AC率/%",
          render: (data, record) => (
            <Progress
              percent={~~((record.totAc! / record.totSubmit!) * 100)}
              size="small"
            />
          )
        }
      ]
    }
  }
  return (
    <Table
      rowKey="id"
      loading={loading}
      dataSource={problems}
      columns={realColumns}
      pagination={false}
      onRow={(...args) => ({
        onClick: (event: Event) => rowClick(...args)
      })}
    />
  )
}
