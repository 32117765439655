import { JudgeResult } from "./problem.model"

export interface ISubmitResult {
  id?: number
  result?: JudgeResult
  username?: string
  timestamp?: number
  memory?: number
  time?: number
  score?: number
  state?: string
  problemId?: number
}

export enum Language {
  CPP = "C++",
  JAVA = "Java"
}
