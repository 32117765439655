import React, { useState, useEffect } from "react"
import {
  useQuery as useQ,
  QueryHookOptions,
  useSubscription as useS,
  SubscriptionHookOptions,
  useMutation as useM,
  MutationHookOptions
} from "react-apollo-hooks"
import { OperationVariables } from "react-apollo"
import gql from "graphql-tag"
import { ApolloError } from "apollo-client"

interface IResult<T> {
  loading: boolean
  error?: ApolloError
  data: T
}

export function useQueryWithPagination<T>(
  limit: number,
  offset: number,
  query: string,
  key?: string,
  variables?: object
): IResult<T> {
  const { loading, error, data } = useQ(gql(query), {
    variables: {
      limit,
      offset,
      ...variables
    }
  })

  if (key && data) {
    return { loading, error, data: data[key] }
  } else {
    return { loading, error, data }
  }
}

export function useQuery<T>(
  query: string,
  options?: QueryHookOptions<OperationVariables>,
  key?: string
): IResult<T> {
  const { loading, error, data } = useQ(gql(query), options)
  if (key && data) {
    return { loading, error, data: data[key] }
  } else {
    return { loading, error, data }
  }
}

export function useSubscription<T>(
  query: string,
  options?: SubscriptionHookOptions<any, OperationVariables>,
  key?: string
): IResult<T> {
  const { loading, error, data } = useS(gql(query), options)
  if (key && data) {
    return { loading, error, data: data[key] }
  } else {
    return { loading, error, data }
  }
}

export function useMutation<T>(
  query: string,
  options?: MutationHookOptions<any, OperationVariables>
) {
  return useM(gql(query), options)
}
