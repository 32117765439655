import { Index } from "../../pages/index/Index"
import { ProblemList, ProblemDetail } from "../../pages/problem"
import {
  ContestList,
  ContestDetail,
  ContestSubmit,
  ContestRank
} from "../../pages/contest"
import { Submits } from "../../pages/submit/Submit"
import { Profile } from "../../pages/user/Profile"
import { ProblemEdit } from "../../pages/admin"
import path2Reg from "path-to-regexp"
export const routes = [
  {
    key: "index",
    path: "/",
    exact: true,
    component: Index
  },
  {
    key: "problem",
    path: "/problem",
    component: ProblemList,
    exact: true
  },
  {
    key: "problemDetail",
    path: "/problem/:id",
    component: ProblemDetail
  },
  {
    key: "contest",
    path: "/contest",
    component: ContestList,
    exact: true
  },
  {
    key: "submit",
    path: "/submit",
    component: Submits,
    exact: true
  },
  {
    key: "profile",
    path: "/profile/:id",
    component: Profile,
    exact: true
  },
  {
    key: "contestDetail",
    path: "/contest/:id",
    component: ContestDetail,
    exact: true
  },
  {
    key: "contestRank",
    path: "/contest/:id/rank",
    component: ContestRank,
    exact: true
  },
  {
    key: "contestSubmit",
    path: "/contest/:id/submit",
    component: ContestSubmit,
    exact: true
  },
  {
    key: "contestProblem",
    path: "/contest/:id/:problem",
    component: ProblemDetail,
    exact: true
  },
  {
    key: "problem-admin",
    path: "/admin/problem",
    component: ProblemEdit,
    exact: true
  }
]

export const getCurrentRoute = (path: string) =>
  routes.reduce(
    (pre, r) => {
      if (pre) return pre
      const regexp = path2Reg(r.path)
      if (regexp.exec(path)) return r
      return null
    },
    null as any
  )
