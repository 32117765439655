import React from "react"
import { useUserInfo } from "../../hooks/user"
import { Avatar, Row, Col, Icon, Menu, Dropdown } from "antd"
import { avatarUrl } from "../../lib/request"
import { withRouter } from "react-router"
import { History } from "history"

const { Item, Divider } = Menu

interface IMenuProps {
  username: string
  history: History
}

const menu: React.FunctionComponent<IMenuProps> = props => {
  const { username, history } = props
  const handleLogout = () => {
    localStorage.removeItem("token")
    window.location.reload()
  }

  const gotoProfile = () => {
    history.push("/profile/" + username)
  }
  return (
    <Menu>
      <Item>
        <Row type="flex" justify="center">
          {username}
        </Row>
      </Item>
      <Divider />
      <Item onClick={gotoProfile}>
        <Icon type="user" />
        个人中心
      </Item>
      <Item onClick={handleLogout}>
        <Icon type="logout" />
        退出
      </Item>
    </Menu>
  )
}

export const UserInfo = withRouter(props => {
  const userInfo = useUserInfo()
  return (
    <Dropdown
      overlay={menu({ username: userInfo.username, history: props.history })}
    >
      <Row type="flex" justify="space-around">
        <Col>
          <Avatar size="large" src={avatarUrl(userInfo.avatar)} />
        </Col>
        <Col>
          <Icon type="more" />
        </Col>
      </Row>
    </Dropdown>
  )
})
