import React, { useState, useEffect } from "react"
import { IUser } from "../models/user.model"
import { useQuery } from "../hooks/graphql"
import { message } from "antd"

const USERINFO_QUERY = `query {
  userInfo {
    username
    avatar
    email
    role
  }
}`

export function useUserInfo() {
  const initState: IUser = {
    username: "",
    email: "",
    avatar: "",
    role: "NORMAL"
  }
  const { loading, error, data } = useQuery<any>(USERINFO_QUERY, {}, "userInfo")
  const [user, setUser] = useState<IUser>(initState)
  useEffect(() => {
    if (!loading && data) {
      setUser(data)
    }
    if (!loading && !data) {
      if (localStorage.getItem("token")) {
        message.error("请重新登录")
      }
    }
  }, [data, loading])
  return user
}
