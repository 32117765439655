import React from "react"
import { Select as S } from "antd"
import { SelectProps as SProps } from "antd/es/select"

const { Option } = S
interface SelectProp extends SProps {
  enumType: any
}

export const Select: React.FunctionComponent<SelectProp> = ({
  enumType,
  ...props
}) => {
  const values = Object.keys(enumType)
  return (
    <S {...props}>
      {values.map(v => (
        <Option value={enumType[v]} key={v}>
          {v}
        </Option>
      ))}
    </S>
  )
}
