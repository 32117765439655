import React from "react"

import { Upload, message } from "antd"
import { avatarUrl, avatarUploadUrl } from "../../lib/request"
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface"

interface IUserAvatarProps {
  avatar: string
  upload: boolean
  onFinished: (res: any) => void
}

const uploadHeaders = {
  authorization: localStorage.getItem("token")
}

const beforeUpload = (file: RcFile) => {
  const accept = ["image/jpeg", "image/png"]
  const isAccept = accept.indexOf(file.type) !== -1
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isAccept) {
    message.error("文件类型错误")
  }
  if (!isLt2M) {
    message.error("文件大小超过2M限制")
  }
  return isAccept && isLt2M
}

export const UserAvatar: React.FunctionComponent<IUserAvatarProps> = props => {
  const { avatar, upload, onFinished } = props

  const onUploadChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      onFinished(info.file.response)
    }
  }
  return (
    <>
      {upload ? (
        <Upload
          listType="picture-card"
          showUploadList={false}
          action={avatarUploadUrl}
          headers={uploadHeaders as any}
          beforeUpload={beforeUpload}
          onChange={onUploadChange}
        >
          <img src={avatarUrl(avatar)} width="150" height="150" />
        </Upload>
      ) : (
        <img src={avatarUrl(avatar)} width="150" height="150" />
      )}
    </>
  )
}
