import React from "react"
import { Card, Divider } from "antd"
import { BadgeRow } from "./Common"

interface IUserContestProps {
  count: number
  rating: number
  style?: React.CSSProperties
}

export const UserContest: React.FunctionComponent<
  IUserContestProps
> = props => {
  const { count, rating } = props
  return (
    <Card title="竞赛" style={props.style}>
      <BadgeRow title="完成的竞赛" icon="calculator" count={count} />
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <BadgeRow title="评分" icon="bulb" count={rating} />
    </Card>
  )
}
