import React from "react"
import { ContestState as CS } from "../../models/contest.model"
import { Badge } from "antd"
import { isNowBeforeDate, isNowAfterDate } from "../../lib/date"

interface ContestStateProps {
  start: number
  duration: number
}

export const workContestState = (start: number, duration: number) => {
  if (isNowBeforeDate(start)) {
    return CS.Pending
  }
  if (isNowAfterDate(start, duration)) {
    return CS.Finished
  }
  return CS.Running
}

export const ContestState: React.FunctionComponent<
  ContestStateProps
> = props => {
  const { start, duration } = props
  const state = workContestState(start, duration)
  const stateMap: { [key: string]: string } = {
    [CS.Finished]: "default",
    [CS.Pending]: "warning",
    [CS.Running]: "success"
  }
  return <Badge status={stateMap[state] as any} />
}
