import React from "react"
import { withRouter } from "react-router-dom"
import { useQuery } from "../../hooks/graphql"
import { ColumnProps } from "antd/lib/table"
import { IRank } from "../../models/contest.model"
import { Table, Spin, Empty } from "antd"

import "./rank.less"

const CONTEST_QUERY = `query ($contestId: Int) {
  contest(id: $contestId) {
    mode
    problemCount
  }
}`

const RANK_QUERY = `query ($contestId: Int) {
  contestRank(id: $contestId) {
    username
    rank
    totAc
    totPenalty
    totScore
    results {
      state
      submit
      penalty
      score
    }
  }
}`

const rankColumn: ColumnProps<IRank> = {
  title: "Rank",
  dataIndex: "rank",
  key: "rank",
  render: (data, record, index) => index + 1
}

const scoreColumn: ColumnProps<IRank> = {
  title: "总分",
  dataIndex: "totScore",
  key: "totScore"
}

const totAcColumn: ColumnProps<IRank> = {
  title: "通过数",
  dataIndex: "totAc",
  key: "totAc"
}

const totPenaltyColumn: ColumnProps<IRank> = {
  title: "总罚时",
  dataIndex: "totPenalty",
  key: "totPenalty"
}

const usernameColumn: ColumnProps<IRank> = {
  title: "用户名",
  dataIndex: "username",
  key: "username"
}

export const ContestRank = withRouter(props => {
  const { match } = props
  const contestId = parseInt(match.params.id, 10)
  const contest = useQuery<any>(
    CONTEST_QUERY,
    { variables: { contestId } },
    "contest"
  )
  const rank = useQuery<IRank[]>(
    RANK_QUERY,
    { variables: { contestId } },
    "contestRank"
  )
  const problemColumns: ColumnProps<IRank>[] = []
  let realColumns: ColumnProps<IRank>[] = []
  if (contest.data) {
    const cnt = contest.data.problemCount
    for (let i = 0; i < cnt; i++) {
      problemColumns.push({
        key: 1000 + i,
        title: 1000 + i,
        render: (data, record) => {
          const result = record.results![i]
          if (contest.data.mode === "ACM") {
            const l = !result.submit ? "-" : result.submit
            const r = !result.penalty ? "--" : result.penalty
            return {
              props: {
                className: result.submit
                  ? result.state
                    ? "contest-rank__col--ac"
                    : "contest-rank__col--submit"
                  : ""
              },
              children: l + "/" + r
            }
          } else {
            const score = result.score
            const submit = result.submit
            return {
              props: {
                className:
                  score === 100
                    ? "contest-rank__col--ac"
                    : submit
                    ? "contest-rank__col--submit"
                    : ""
              },
              children: submit ? score : "--"
            }
          }
        },
        width: 80
      })
    }
    if (contest.data.mode === "ACM") {
      realColumns = [
        rankColumn,
        usernameColumn,
        totAcColumn,
        totPenaltyColumn,
        ...problemColumns
      ]
    } else {
      realColumns = [rankColumn, usernameColumn, scoreColumn, ...problemColumns]
    }
  }
  return (
    <>
      {rank.loading || contest.loading ? (
        <Spin />
      ) : rank.error || contest.error ? (
        <Empty />
      ) : (
        <div className="contest-rank">
          <Table
            className="no-hover"
            bordered
            rowKey="username"
            dataSource={rank.data}
            columns={realColumns}
            pagination={false}
          />
        </div>
      )}
    </>
  )
})
