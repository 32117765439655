import { IProblem } from "./problem.model"
export enum ContestState {
  "Pending" = "Pending",
  "Finished" = "Finished",
  "Running" = "Running"
}

export interface IContest {
  id?: number
  title?: string
  extraInfo?: string
  startAt?: number
  duration?: number
  startRegisterAt?: number
  registerDuration?: number
  mode?: string
  attendMode?: string
  permissionType?: string
  blocked?: boolean
  blockDuration?: number
  problemCount?: number
  state?: string
  joined?: boolean
  problems?: IProblem[]
}

export interface IRankProblemResult {
  state?: boolean
  penalty?: number
  submit?: number
  score?: number
}
export interface IRank {
  rank?: number
  username?: string
  totAc?: number
  totPenalty?: number
  totScore?: number
  results?: IRankProblemResult[]
}

export interface recentContestItem {
  id: string
  oj: string
  link: string
  name: string
  start_time: string
  week: string
}
