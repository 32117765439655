import React, { useEffect, useState } from "react"
import { Menu as AntdMenu, Icon } from "antd"
import { Link, withRouter } from "react-router-dom"
import { routes } from "./routes"
import { useUserInfo } from "../../hooks/user"
import { userInfo } from "os"
const { SubMenu, Item } = AntdMenu
export const Menu = withRouter(props => {
  const { location } = props
  const { pathname } = location
  const route = routes.find(i => i.path === pathname) || { key: "" }
  const user = useUserInfo()
  return (
    <AntdMenu defaultSelectedKeys={[route!.key]} mode="inline">
      <Item key="index">
        <Link to="/">
          <Icon type="dashboard" />
          主页
        </Link>
      </Item>
      <Item key="problem">
        <Link to="/problem">
          <Icon type="experiment" />
          题库
        </Link>
      </Item>
      <Item key="contest">
        <Link to="/contest">
          <Icon type="crown" />
          比赛
        </Link>
      </Item>
      <Item key="submit">
        <Link to="/submit">
          <Icon type="compass" />
          状态
        </Link>
      </Item>
      {/* {user.role === "ADMIN" && (
        <SubMenu
          key="admin"
          title={
            <>
              <Icon type="robot" /> 管理
            </>
          }
        >
          <Item key="problem-admin">
            <Link to="/admin/problem">
              <Icon type="reconciliation" />
              题目管理
            </Link>
          </Item>
        </SubMenu>
      )} */}
    </AntdMenu>
  )
})
