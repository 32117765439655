import React from "react"
import { IUserSubmitCount } from "../../models/user.model"
import { Card, Row, Col, Divider } from "antd"
import { BadgeRow } from "./Common"
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from "bizcharts"
import { JudgeResult } from "../../models/problem.model"
const DataSet = require("@antv/data-set")

interface ISubmitStateChartProps {
  data: IUserSubmitCount[]
  totSubmit: number
  accept: number
  resolve: number
}

const { DataView } = DataSet

const cols = {
  percent: {
    formatter: (val: number) => {
      return val * 100 + "%"
    }
  }
}

export const SubmitState: React.FunctionComponent<
  ISubmitStateChartProps
> = props => {
  const { data, totSubmit, resolve, accept } = props
  const chartData = data.map(d => ({ ...d, item: JudgeResult[d.state] }))
  const dv = new DataView()
  dv.source(chartData).transform({
    type: "percent",
    field: "count",
    dimension: "item",
    as: "percent"
  })
  return (
    <Card title="进展" style={{ marginTop: 10 }}>
      <BadgeRow title="已解决的问题" icon="question" count={resolve} />
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <BadgeRow title="总的提交" icon="setting" count={totSubmit} />
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      {totSubmit ? (
        <Chart
          height={300}
          data={dv}
          scale={cols}
          padding={[0, 0, 0, 0]}
          forceFit
        >
          <Coord type="theta" radius={0.75} />
          <Axis name="percent" />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Geom
            type="intervalStack"
            position="percent"
            color="item"
            tooltip={[
              "item*percent",
              (item, percent: number) => {
                percent = percent * 100
                return {
                  name: item,
                  value: percent.toFixed(2) + "%"
                }
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: "#fff"
            }}
          />
        </Chart>
      ) : (
        <> </>
      )}
    </Card>
  )
}
