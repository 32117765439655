import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Typography, Progress, Spin, Empty } from "antd"
import "./detail.less"
import { MarkedCard } from "../../components/common"
import { withRouter } from "react-router"
import { useQuery } from "../../hooks/graphql"
import { IContest } from "../../models/contest.model"
import { format, formatDuration } from "../../lib/date"
import { ProblemTable } from "../../components/tables"

const { Title } = Typography

const DETAIL_QUERY = `query ($id: Int) {
  contest(id: $id) {
    problemCount
    title
  	extraInfo
    startAt
    duration
    startRegisterAt
    registerDuration
    mode
    attendMode
    permissionType
    problems {
      id
      title
      timeLimit
      memoryLimit
  	}
  }
}`

export const ContestDetail = withRouter(props => {
  const { match, history } = props
  const contestId = parseInt(match.params.id, 10)
  const [now, setNow] = useState(+new Date())
  useEffect(() => {
    const id = setInterval(() => {
      setNow(+new Date())
    }, 1000)
    return () => clearInterval(id)
  }, [])
  const { loading, error, data } = useQuery<IContest>(
    DETAIL_QUERY,
    { variables: { id: contestId } },
    "contest"
  )
  const rowClick = (row: any) => {
    history.push(`/contest/${contestId}/${row.id}?from=contest&mode=${data.mode}`)
  }

  return (
    <>
      {loading ? (
        <Spin />
      ) : error ? (
        <Empty />
      ) : (
        <div className="contest-detail">
          <Row>
            <Progress
              strokeLinecap="square"
              percent={((now - data.startAt!) / data.duration!) * 100}
              status={
                (now - data.startAt!) / data.duration! > 1
                  ? "exception"
                  : "active"
              }
              showInfo={false}
            />
          </Row>
          <Row justify="center" type="flex">
            <Col
              span={12}
              style={{ padding: 14, height: "100%" }}
              className="flex-col"
            >
              <Row type="flex" justify="center">
                <Title level={2}>{data.title}</Title>
              </Row>
              <Row className="contest-detail__info">
                <div>
                  <MarkedCard markdown={data.extraInfo!} />
                </div>
              </Row>
            </Col>
            <Col
              span={12}
              style={{ padding: 14, height: "100%" }}
              className="flex-col"
            >
              <Row type="flex" justify="start">
                <Title level={4}>时间：{format(data.startAt)}</Title>
              </Row>
              <Row type="flex" justify="start">
                <Title level={4}>时长：{formatDuration(data.duration!)}</Title>
              </Row>
              <Row type="flex" justify="start">
                <Title level={4}>模式：{data.mode}</Title>
              </Row>
              <Row type="flex" justify="start">
                <Title level={4}>题数：{data.problemCount} 题</Title>
              </Row>
              <div className="contest-detail__problems">
                <ProblemTable
                  problems={data.problems!}
                  loading={loading}
                  rowClick={rowClick}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
})
